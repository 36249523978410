import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import sanitize from "~/helpers/sanitize"

import Casino from "~/models/casino"
import StarIcon from "~/ui/identity/icon/star"
import Check from "~/ui/identity/icon/check"
import Cross from "~/ui/identity/icon/cross"
import Image from "~/ui/elements/image/image"
import Author from "~/models/author"

type Props = {
  className?: string
  casino: Casino
  author: Author
  description: string
}

function ExpertReview({ className, casino, description, author }: Props) {
  return (
    <section id="expert-review" className={cx("bg-blue-200", className)}>
      <div className="max-w-screen-xl mx-auto py-6 lg:py-20 px-4 md:px-10 xl:px-20 ">
        <h2 className="mb-4 lg:mb-8 text-4xl lg:text-5xl font-bold text-font-1 text-center">
          Expert review
        </h2>

        <div className="md:flex gap-10 md:mb-8">
          <div>
            <h2 className="font-bold text-4xl text-font-1 mb-4 text-center md:text-left">
              {casino.name} Review
            </h2>
            <p
              className="mb-10 text-lg text-font-2"
              dangerouslySetInnerHTML={sanitize(description)}
            />
          </div>

          <div className="justify-center lg:min-w-[165px] md:pl-8 md:border-l border-font-3 shrink-0 flex md:block gap-4">
            <div className="py-2 px-4 flex gap-1 items-center bg-white rounded-full mb-4 md:max-w-[180px]">
              <StarIcon width={36} color="#FFC939" />
              <span className="text-4xl font-bold text-font-1">8.0/10</span>
            </div>

            <div className="mb-4">
              <div className="text-sm text-font-1 text-center md:mb-4">
                Tested by
              </div>

              <div className="flex items-center gap-2">
                <Image
                  className="block w-6 h-6 rounded-full isolate"
                  gatsbyImageData={author.avatar}
                  alt={author.name || ""}
                />
                <div>
                  <Link
                    to={`/about-us/${author.slug}`}
                    className="text-sm font-bold text-blue-600 leading-none"
                  >
                    {author.name}
                  </Link>
                  <p className="text-xs text-font-1 leading-none">
                    India Casino Expert
                  </p>
                </div>
              </div>
            </div>

            <div className="hidden md:flex gap-4">
              <div className="flex items-center gap-1 py-1 px-2 bg-green-300 text-green-700 text-xs rounded-full max-w-[160px]">
                <img
                  src="/images/general/check-badge.svg"
                  alt="check"
                  className=""
                  width="12"
                  decoding="async"
                />
                <span>Personally tested</span>
              </div>

              <div className="flex items-center gap-1 py-1 px-2 bg-yellow-600 text-green-700 text-xs rounded-full max-w-[160px]">
                <img
                  src="/images/general/shield-check.svg"
                  alt="verified"
                  className=""
                  width="12"
                  decoding="async"
                />
                <span>Verified review</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-8 flex md:hidden gap-4 justify-center">
          <div className="flex items-center gap-1 py-1 px-2 bg-green-300 text-green-700 text-xs rounded-full max-w-[160px]">
            <img
              src="/images/general/check-badge.svg"
              alt="check"
              className=""
              width="12"
              decoding="async"
            />
            <span>Personally tested</span>
          </div>

          <div className="flex items-center gap-1 py-1 px-2 bg-yellow-600 text-green-700 text-xs rounded-full max-w-[160px]">
            <img
              src="/images/general/shield-check.svg"
              alt="verified"
              className=""
              width="12"
              decoding="async"
            />
            <span>Verified review</span>
          </div>
        </div>

        <div className="flex gap-6">
          <div className="flex w-full flex-col lg:flex-row gap-6 lg:gap-10">
            <div className="flex-1 p-5 bg-white rounded-lg">
              <h3 className="mb-4 lg:mb-6 text-lg lg:text-2xl font-1 font-bold">
                Pros
              </h3>
              {casino.pros.map((pro, index) => {
                return (
                  <div key={index} className="flex mb-3 items-center">
                    <Check
                      width={24}
                      className="w-[16px] lg:w-[24px] mr-4 flex-shrink-0"
                    />
                    <span className="text-lg text-font-2">{pro}</span>
                  </div>
                )
              })}
            </div>

            <div className="flex-1 mb-8 lg:mb-0 flex-shrink-0 p-5 bg-white rounded-lg">
              <h3 className="mb-4 lg:mb-6 text-lg lg:text-2xl font-1 font-bold">
                Cons
              </h3>
              {casino.cons.map((con, index) => {
                return (
                  <div key={index} className="flex mb-3 items-center">
                    <Cross
                      width={24}
                      className="w-[16px] lg:w-[24px] mr-4 flex-shrink-0"
                    />
                    <span className="text-lg text-font-2">{con}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertReview
