import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Casino from "~/models/casino"
import Stars from "~/ui/elements/review/stars"

const h2 = (props: any) => (
  <h2 className="mr-6 mt-10 lg:mt-20 mb-4 text-3xl lg:text-4xl font-bold text-font-1 text-center">
    {props.children}
  </h2>
)

const h3 = (props: any) => (
  <h3 className="mt-10 mb-4 text-lg lg:text-2xl font-bold text-font-1">
    {props.children}
  </h3>
)

const h4 = (props: any) => (
  <h4 className="mt-10 mb-4 lg:text-2xl font-medium text-font-1">
    {props.children}
  </h4>
)

const h5 = (props: any) => (
  <h5 className="mt-10 mb-4 lg:text-2xl font-medium text-font-1">
    {props.children}
  </h5>
)

const p = (props: any) => (
  <p className="mb-8 text-lg text-font-2">{props.children}</p>
)

const strong = (props: any) => (
  <strong className="text-font-1">{props.children}</strong>
)

const ol = (props: any) => <ol className="my-8 mb-4" {...props} />

const a = (props: any) => <a className="underline font-bold" {...props} />

const ul = (props: any) => <ul className="my-8 mb-4" {...props} />

const li = (props: any) => (
  <li className="flex gap-2 lg:gap-4 mb-2 last:mb-0 items-start">
    <img
      src="/images/general/list-check.svg"
      alt=""
      className="w-6 h-6"
      decoding="async"
    />
    <span className="text-lg text-font-2">{props.children}</span>
  </li>
)

const _Stars = (props: any) => (
  <div className="mb-8 flex items-center gap-2 justify-center py-2 px-4 bg-yellow-200 rounded-full w-[200px] mx-auto">
    <Stars rating={props.rating} />
    <span className="text-lg text-font-1 font-bold">{props.rating}</span>
  </div>
)

const _Description = (props: any) => (
  <p className="max-w-[548px] text-font-2 text-center mx-auto mb-4 text-lg lg:text-xl">
    {props.children}
  </p>
)

const _Image = (props: any) => (
  <span className="mb-8 block w-full mx-auto">
    <img
      className="mb-1 rounded-3xl overflow-hidden"
      {...props}
      src={`${props.src}?fm=webp&fit=fill&w=738`}
      title={props.alt}
      loading="lazy"
    />
    <span className="text-xs text-gray-80">{props.alt}</span>
  </span>
)

const components = {
  h2,
  h3,
  h4,
  h5,
  p,
  strong,
  ul,
  img: _Image,
  ol,
  li,
  a,
  Description: _Description,
  Stars: _Stars,
}

type Props = {
  title: string
  description: string
  casino: Casino
  children: string
  className?: string
}

function CasinoReview({ children }: Props) {
  return (
    <React.Fragment>
      <section>
        <div className="max-w-[738px] mx-auto pb-6 lg:pb-20 px-4 md:px-0">
          <MDXProvider components={components}>
            <MDXRenderer>{children}</MDXRenderer>
          </MDXProvider>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CasinoReview
