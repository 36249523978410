import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import Casino from "~/models/casino"

import Image from "~/ui/elements/image/image"
import PartnerLink from "~/ui/elements/button/partner-link"
import LocalImage from "~/ui/elements/image/local"

type Props = {
  casino: Casino
  title: string
  className?: string
}

function CasinoData({ casino, className }: Props) {
  const location = useLocation()

  const welcomeBonus = casino.bonuses.find(
    bonus => bonus.type == "Welcome Bonus"
  )

  return (
    <React.Fragment>
      <section className="overflow-scroll scrollbar-hidden max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20 z-10">
        <div className="lg:min-w-[1120px] p-1 bg-font-1 rounded-lg flex justify-between">
          <Link
            to={`${casino.page?.url.pathname}#overview`}
            className={cx(
              "items-center flex-1 justify-center flex gap-2 text-sm text-white py-3 px-3 rounded-[6px]",
              {
                "bg-blue-600 text-white":
                  location.hash == "#overview" || !location.hash,
              }
            )}
          >
            <img
              src="/images/general/filter-overview.svg"
              alt=""
              className="w-[20px]"
              decoding="async"
            />
            <span>Overview</span>
          </Link>
          <Link
            to={`${casino.page?.url.pathname}#expert-review`}
            className={cx(
              "items-center flex-1 justify-center flex gap-2 text-sm text-white py-3 px-3 rounded-[6px]",
              {
                "bg-blue-600 text-white": location.hash == "#expert-review",
              }
            )}
          >
            <img
              src="/images/general/filter-review.svg"
              alt=""
              className="w-[20px]"
              decoding="async"
            />
            <span>Expert Review</span>
          </Link>
          {/* <Link
            to={`${casino.page?.url.pathname}#faq`}
            className={cx(
              "items-center flex-1 justify-center flex gap-2 text-sm text-white py-3 px-3 rounded-[6px]",
              {
                "bg-blue-600 text-white": location.hash == "#faq",
              }
            )}
          >
            <img
              src="/images/general/filter-faq.svg"
              alt=""
              className="w-[20px]"
              decoding="async"
            />
            <span>FAQs</span>
          </Link> */}
        </div>
      </section>

      <section id="overview" className={className}>
        <div className="max-w-screen-xl mx-auto py-6 lg:py-20 px-4 md:px-10 xl:px-20">
          <h2 className="mb-10 text-3xl lg:text-6xl font-bold text-font-1 text-center">
            Overview
          </h2>

          <div className="flex flex-col md:flex-row md:items-center justify-between gap-10">
            <div>
              <div className="mb-6 grid grid-cols-2 gap-6">
                {welcomeBonus && (
                  <div>
                    <div className="mb-2 text-sm lg:text-lg text-font-2">
                      Welcome Bonus
                    </div>
                    <div className="text-lg lg:text-2xl font-medium text-font-1">
                      {welcomeBonus.value}
                    </div>
                  </div>
                )}
                <div>
                  <div className="mb-2 text-sm lg:text-lg text-font-2">
                    Games
                  </div>
                  <div className="text-lg lg:text-2xl font-medium text-font-1">
                    {casino.numberOfGames}
                  </div>
                </div>
                <div>
                  <div className="mb-2 text-sm lg:text-lg text-font-2">
                    Payout Percentage
                  </div>
                  <div className="text-lg lg:text-2xl font-medium text-font-1">
                    {casino.payoutPercentage}
                  </div>
                </div>
                <div>
                  <div className="mb-2 text-sm lg:text-lg text-font-2">
                    Founded
                  </div>
                  <div className="text-lg lg:text-2xl font-medium text-font-1">
                    {casino.foundationYear}
                  </div>
                </div>
                <div>
                  <div className="mb-2 text-sm lg:text-lg text-font-2">
                    Payouts
                  </div>
                  <div className="text-lg lg:text-2xl font-medium text-font-1">
                    {casino.payoutPeriod}
                  </div>
                </div>
                <div>
                  <div className="mb-2 text-sm lg:text-lg text-font-2">
                    Operating Systemss
                  </div>
                  <div className="text-lg lg:text-2xl font-medium text-font-1">
                    {casino.operatingSystems.join(", ")}
                  </div>
                </div>
              </div>

              <div>
                <div className="mb-2 text-sm lg:text-lg text-font-2">
                  PaymentOptions
                </div>
                <div>
                  {casino.paymentMethods.map((method, index) => {
                    return (
                      <LocalImage
                        key={index}
                        className="inline-block mr-6 last:mr-0 mb-6"
                        filename={`payment/${method
                          .toLowerCase()
                          .replace(/\s/g, "")}.png`}
                        alt={method}
                        width={84}
                      />
                    )
                  })}
                </div>
              </div>
            </div>

            {welcomeBonus && (
              <div className="lg:w-[260px] bg-indigo-50 rounded-xl px-8 py-6 flex flex-col items-center gap-4">
                <Image
                  className="block w-[120px]"
                  gatsbyImageData={casino.logo}
                  alt={casino.name}
                  zoom
                />
                <p className="lg:text-lg text-center text-font-2">
                  {welcomeBonus.tagline}
                </p>
                <PartnerLink type="primary" casino={casino} className="px-10">
                  Claim Bonus
                </PartnerLink>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className={className}>
        <div className="max-w-screen-xl mx-auto py-6 lg:py-20 px-4 md:px-10 xl:px-20">
          <h2 className="mb-10 text-3xl lg:text-6xl font-bold text-font-1">
            Available games
          </h2>

          <div className="grid grid-cols-2 sm:block cf">
            {casino.availableGames.map((game, index) => {
              return (
                <div
                  key={index}
                  className="sm:float-left py-1 px-2 bg-[#EFF0FF] mr-3 lg:mr-5 mb-5"
                >
                  <div className="flex gap-1 ">
                    <img
                      src={`/images/game/${game
                        .toLowerCase()
                        .replace(/\s/g, "")}.svg`}
                      alt=""
                      className="w-6"
                      decoding="async"
                    />
                    <span className="text-sm lg:text-lg text-blue-600">
                      {game}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CasinoData
