import React from "react"
import cx from "classnames"

import Casino from "~/models/casino"

import Image from "~/ui/elements/image/image"
import Stars from "~/ui/elements/review/stars"
import PartnerLink from "~/ui/elements/button/partner-link"

type Props = {
  casino: Casino
  title: string
  className?: string
}

function CasinoInfo({ casino, title, className }: any) {
  return (
    <div className={className}>
      <h1 className="mb-4 text-3xl lg:text-6xl font-bold text-font-1">
        <span className="align-middle">{title}</span>
      </h1>

      <div className="flex gap-4 mb-7">
        <div className="flex align-start mb-4">
          <span className="mr-1 leading-none text-5xl text-font-1 font-bold">
            {casino.rating.toFixed(1)}
          </span>
          <span className="mr-1 text-2xl leading-none text-font-2">/</span>
          <span className="text-2xl leading-none text-font-2">5</span>
        </div>

        <div>
          <Stars className="mb-2" rating={casino.rating} color="#FF8501" />
          <span className="text-xs leading-none text-font-2">
            Expert Evaluation
          </span>
        </div>
      </div>

      <PartnerLink
        type="primary"
        casino={casino}
        className="w-full lg:w-auto px-10 inline-block"
      >
        Play Now
      </PartnerLink>
    </div>
  )
}

function CasinoHeader({ casino, title, className }: Props) {
  const welcomeBonus = casino.bonuses.find(
    bonus => bonus.type == "Welcome Bonus"
  )

  return (
    <React.Fragment>
      <section className={cx("overflow-hidden bg-yellow-300", className)}>
        <div className="relative justify-between flex items-center max-w-screen-xl mx-auto  py-9 px-4 md:px-10 xl:px-20">
          <img
            src="/images/general/mandala-yellow-2.svg"
            alt=""
            className="w-[220px] absolute -bottom-[36px] -right-[92px] lg:-top-[15px] lg:-right-[31px]"
            decoding="async"
          />

          <div className="w-full flex gap-10 justify-center sm:justify-start z-10">
            <Image
              className="block w-[216px] h-[216px]"
              gatsbyImageData={casino.logo}
              alt={casino.name}
            />

            <CasinoInfo
              casino={casino}
              title={title}
              className="hidden sm:block"
            />
          </div>

          <img
            src="/images/general/mandala-yellow-1.svg"
            alt=""
            className="w-[200px] lg:w-[704px] absolute -top-[32px] lg:-top-[68px] -left-[60px] lg:-left-[206px]"
            decoding="async"
          />
        </div>
      </section>

      {welcomeBonus && (
        <section className="overflow-hidden py-4 lg:py-9 bg-font-1">
          <div className="justify-between flex gap-6 items-center max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
            <p className="text-xl lg:text-4xl text-white leading-none">
              {welcomeBonus.tagline}
            </p>
            <PartnerLink type="primary" casino={casino} className="px-10">
              Claim Bonus
            </PartnerLink>
          </div>
        </section>
      )}

      <section className="sm:hidden overflow-hidden py-6 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
          <CasinoInfo casino={casino} title={title} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default CasinoHeader
