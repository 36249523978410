import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import Image from "~/ui/elements/image/image"
import Author from "~/models/author"

type Props = {
  className?: string
  author: Author
}

function AuthorBlock({ author, className }: Props) {
  return (
    <section
      className={cx(
        "max-w-screen-xl mx-auto pb-10 md:pb-16 px-4 md:px-10 xl:px-20 py-12 lg:py-20",
        className
      )}
    >
      <div className="max-w-[738px] mx-auto p-8 bg-blue-200 rounded-lg flex-col sm:flex-row flex gap-10">
        <div>
          <p className="mb-4 text-2xl font-bold text-font-1">
            Authentic reviews
          </p>
          <p className="mb-4 text-font-1">
            Shahanshah Alam is the online Gaming specialist for India. For
            Gambling.IN Shahanshah has personally tested Betway to help you
            making smart choices online when picking your next online casino
            experience.
          </p>

          <div className="flex gap-4">
            <div className="flex items-center gap-1 py-1 px-2 bg-green-300 text-green-700 text-xs rounded-full max-w-[160px]">
              <img
                src="/images/general/check-badge.svg"
                alt="check"
                className=""
                width="12"
                decoding="async"
              />
              <span>Personally tested</span>
            </div>

            <div className="flex items-center gap-1 py-1 px-2 bg-yellow-600 text-green-700 text-xs rounded-full max-w-[160px]">
              <img
                src="/images/general/shield-check.svg"
                alt="verified"
                className=""
                width="12"
                decoding="async"
              />
              <span>Verified review</span>
            </div>
          </div>
        </div>

        <div className="shrink-0">
          <Image
            className="block w-[86px] h-[86px] rounded-full z-10 mb-2 mx-auto"
            gatsbyImageData={author.avatar}
            alt={author.name}
          />

          <Link
            to={`/about-us/${author.slug}`}
            className="block mb-1 text-lg text-blue-600 underline font-bold text-center"
          >
            {author.name}
          </Link>

          <p className="text-font-1 text-center">India Casino Expert</p>
        </div>
      </div>
    </section>
  )
}

export default AuthorBlock
