import React from "react"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Casino from "~/models/casino"
import GameCollection from "~/models/game-collection"
import Article from "~/models/article"

import ArticlesBlock from "~/ui/compositions/article/list"
import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import GameListBlock from "~/ui/compositions/game/list"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import CasinoHeaderBlock from "~/ui/compositions/casino/header"
import CasinoDataBlock from "~/ui/compositions/casino/data"
import ReviewBlock from "~/ui/compositions/casino/review"
import ExpertReviewBlock from "~/ui/compositions/casino/expert-review"
import AuthorBlock from "~/ui/compositions/casino/author"
import SerpTable from "~/ui/components/stable/serp-table"

import { graphql } from "gatsby"
import {
  CasinoPageQuery,
  ContentfulCasino,
  ContentfulPage,
  ContentfulGameCollection,
  ContentfulArticle,
} from "../../../types/graphql-types"

export const query = graphql`
  query CasinoPage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulCasino(slug: { eq: $slug }) {
      ...CasinoFields
    }
    contentfulGameCollection(type: { eq: $slug }) {
      ...GameCollectionFields
    }
    allContentfulArticle(limit: 3) {
      nodes {
        ...ArticleFields
      }
    }
  }
`

type Props = {
  data: CasinoPageQuery
}

const CasinoPage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)
  const casino = new Casino(data.contentfulCasino as ContentfulCasino)
  const gameCollection = new GameCollection(
    data.contentfulGameCollection as ContentfulGameCollection
  )
  const articles = data.allContentfulArticle.nodes.map(article => {
    return new Article(article as ContentfulArticle)
  })
  return (
    <Frame>
      <Analytics page={page} />
      <SEO page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "CasinoHeader" && (
              <React.Fragment>
                <CasinoHeaderBlock
                  casino={casino}
                  title={page.title}
                  {...block.props}
                />
                <div className="mb-2 mt-2">
                  <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                    <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                  </div>
                </div>
              </React.Fragment>
            )}
            {block.name == "Author" && <AuthorBlock author={page.author} />}
            {block.name == "CasinoData" && (
              <CasinoDataBlock casino={casino} {...block.props} />
            )}
            {block.name == "GameList" && (
              <GameListBlock collection={gameCollection} {...block.props} />
            )}
            {block.name == "ExpertReview" && (
              <ExpertReviewBlock
                casino={casino}
                author={page.author}
                {...block.props}
              />
            )}
            {block.name == "Review" && (
              <ReviewBlock casino={casino} {...block.props}>
                {block.content.childMdx?.body}
              </ReviewBlock>
            )}
            {block.name == "Articles" && (
              <ArticlesBlock articles={articles} {...block.props} />
            )}
            {block.name == "GameList" && page.serpTable && (
              <SerpTable serpTable={page.serpTable} />
            )}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default CasinoPage
